import React, { useState } from "react"
import styled from "styled-components"
import is from "styled-is"
import { motion, AnimatePresence } from "framer-motion"

import Img from "gatsby-image"

// Common Componetns
import { SanityPortableText, StandardContainer } from "../../common"

// Styles

const StyledTabbedImageSet = styled.div`
  --grid-cols: ${props => props.gridColumns};
  display: grid;

  @media ${props => props.theme.breakpoints.medium} {
    grid-template-columns: repeat(var(--grid-cols), 1fr);
    grid-gap: 3rem;
  }
`

const StyledTabbedImages = styled.div`
  > nav {
    margin: 1rem 0;
    display: flex;
    > button {
      margin-right: 0.1rem;
    }
  }
  @media ${props => props.theme.breakpoints.medium} {
    > nav button {
      margin-right: 0.5rem;
    }
  }
`
const StyledTabButton = styled.button`
  font-family: ${props => props.theme.typography.family.bold};
  opacity: 0.5;
  ${is("active")`
    opacity: 1;
    text-decoration: underline;
  `}
`

const TabbedImage = ({ asset, ...props }) => {
  return (
    <motion.div
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
        y: 0,
      }}
      exit={{
        opacity: 0,
      }}
      transition={{
        transition: {
          type: "spring",
          damping: 600,
        },
      }}
      {...props}
    >
      {asset && <Img fluid={asset.fluid} />}
    </motion.div>
  )
}

const TabbedImages = ({ images }) => {
  const [tabIndex, setTabIndex] = useState(0)
  return (
    <StyledTabbedImages>
      <nav>
        {images.map(({ title }, index) => (
          <StyledTabButton
            active={tabIndex === index}
            onClick={() => setTabIndex(index)}
          >
            {title}
          </StyledTabButton>
        ))}
      </nav>
      <AnimatePresence exitBeforeEnter>
        <TabbedImage key={tabIndex} {...images[tabIndex]} />
      </AnimatePresence>
    </StyledTabbedImages>
  )
}

export const TabbedImageSet = ({
  gridColumns,
  _rawSectionDescription,
  sectionTabs,
  marginTop,
  marginBottom,
}) => {
  return (
    <StandardContainer marginTop={marginTop} marginBottom={marginBottom}>
      {_rawSectionDescription && (
        <SanityPortableText text={_rawSectionDescription} />
      )}
      <StyledTabbedImageSet gridColumns={gridColumns}>
        {sectionTabs.map(item => (
          <TabbedImages {...item} />
        ))}
      </StyledTabbedImageSet>
    </StandardContainer>
  )
}
