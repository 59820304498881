import React, { useState } from "react"
import styled from "styled-components"

// Hooks
import { useAppContext } from "../../../state"
import { useWindowSize } from "../../../hooks"

import { MobileWrapper, SystemNav } from "./"

// Const Styles
const StyledDeskNavWrapper = styled.div`
  > nav {
    position: sticky;
    top: ${props => (props.headerPinned ? "6rem" : "2rem")};
    transition: top 0.2s ease;
    left: 0;
  }
`

export const SystemNavWrapper = ({ blockSections }) => {
  const windowSize = useWindowSize()
  const { headerPinned } = useAppContext()
  const [activeSection, setActiveSection] = useState({})
  return (
    <>
      {windowSize.width > 1024 ? (
        <StyledDeskNavWrapper headerPinned={headerPinned}>
          <SystemNav
            sections={blockSections}
            activeSection={activeSection}
            setActiveSection={setActiveSection}
          />
        </StyledDeskNavWrapper>
      ) : (
        <MobileWrapper
          sections={blockSections}
          activeSection={activeSection}
          setActiveSection={setActiveSection}
        />
      )}
    </>
  )
}
