import React from "react"

import styled from "styled-components"

// Components
import { SanityPortableText, StandardContainer } from "../../common"

const StyledShadeTable = styled.div`
  display: grid;
  grid-row-gap: 0.8rem;
  margin-top: 3rem;
`
const StyledShadeRow = styled.div`
  --col-count: ${props => props.cols};
  display: grid;
  grid-template-columns: repeat(var(--col-count), 1fr);
  grid-gap: 0.3rem;

  @media ${props => props.theme.breakpoints.medium} {
    grid-gap: 0.8rem;
  }
`

const StyledShadeTile = styled.div`
  background-color: ${props => props.hex};
  padding: 0.8rem;
  opacity: ${props => props.alpha || 1};
  font-size: 0;
  @media ${props => props.theme.breakpoints.medium} {
    padding: 1rem;
  }
`

const ShadeSet = ({ shades }) => {
  return (
    <StyledShadeRow cols={shades.length}>
      {shades.map(item => (
        <StyledShadeTile {...item}>{item.hex}</StyledShadeTile>
      ))}
    </StyledShadeRow>
  )
}

export const ColorShades = ({
  colorShades,
  _rawSectionDescription,
  marginTop,
  marginBottom,
}) => {
  return (
    <StandardContainer marginTop={marginTop} marginBottom={marginBottom}>
      <SanityPortableText text={_rawSectionDescription} />
      <StyledShadeTable>
        {colorShades.map(item => (
          <ShadeSet {...item} />
        ))}
      </StyledShadeTable>
    </StandardContainer>
  )
}
