import React from "react"
import styled from "styled-components"

// hooks
import { useToggle } from "../../../hooks"
import { useAppContext } from "../../../state"

// Common compoents
import { SystemNav } from "./systemNav"
import { StandardContainer, MotionContainer } from "../../common"

const StyledNavHeader = styled(StandardContainer)`
  position: sticky;
  top: ${props => (!props.headerPinned ? "0px" : "64px")};
  display: flex;
  align-items: center;
  width: 100%;
  height: 3rem;
  background: ${props => props.theme.colors.primary.two};
  color: #fff;
  transition: top 0.2s ease;
  z-index: 99;
  @media ${props => props.theme.breakpoints.medium} {
    top: ${props => (!props.headerPinned ? "0px" : "84px")};
  }
`
const StyledMobileNavWrapper = styled(MotionContainer)`
  height: 100%;
  position: fixed;
  background: #fff;
  width: 100%;
  z-index: 95;
  bottom: 0;
  left: 0;
  transition: height 0.2s ease;
  padding-top: ${props => (props.headerPinned ? "148px" : "80px")};

  @media ${props => props.theme.breakpoints.medium} {
    padding-top: ${props => (props.headerPinned ? "180px" : "80px")};
  }
`

const variants = {
  open: {
    y: 0,
  },
  closed: {
    y: "100%",
  },
}

export const MobileWrapper = ({
  sections,
  setActiveSection,
  activeSection,
}) => {
  const { headerPinned } = useAppContext()

  const [isOpen, setOpen, toggleOpen] = useToggle(false)
  return (
    <>
      <StyledNavHeader
        as="header"
        padH
        onClick={toggleOpen}
        headerPinned={headerPinned}
      >
        {activeSection.parent} - {activeSection.sub}
      </StyledNavHeader>
      <StyledMobileNavWrapper
        padH
        headerPinned={headerPinned}
        variants={variants}
        initial="closed"
        animate={isOpen ? "open" : "closed"}
        transition={{
          type: "spring",
          damping: 300,
        }}
      >
        <SystemNav
          sections={sections}
          activeSection={activeSection}
          setActiveSection={setActiveSection}
          onClose={() => setOpen(false)}
        />
      </StyledMobileNavWrapper>
    </>
  )
}
