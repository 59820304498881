import React from "react"

// Components
import { SanityPortableText, StandardContainer } from "../../common"

export const TextBlock = ({ _rawText, marginTop, marginBottom }) => {
  return (
    <StandardContainer marginTop={marginTop} marginBottom={marginBottom}>
      <SanityPortableText text={_rawText} />
    </StandardContainer>
  )
}
