import React from "react"
import styled from "styled-components"

// Standard Container
import { StandardContainer } from "../common"
import { SystemElement } from "./"
import { SystemNavWrapper } from "./nav/systemNavWrapper"

// Styles
const StyledSystemElementsBlock = styled(StandardContainer)`
  margin-top: 70px;
  > article {
    padding-left: ${props => props.theme.padding.hMobile};
    padding-right: ${props => props.theme.padding.hMobile};
    padding-top: 3rem;
  }
  @media ${props => props.theme.breakpoints.medium} {
    > article {
      padding-left: ${props => props.theme.padding.hDesk};
      padding-right: ${props => props.theme.padding.hDesk};
    }
  }
  @media ${props => props.theme.breakpoints.large} {
    margin-top: 150px;
    display: grid;
    grid-template-columns: 250px minmax(0, 1fr);
    grid-column-gap: 2rem;
    margin-bottom: 20rem;
    padding-left: ${props => props.theme.padding.hDesk};
    padding-right: ${props => props.theme.padding.hDesk};
    > article {
      padding: 0;
    }
  }
  @media ${props => props.theme.breakpoints.xl} {
    grid-template-columns: 400px 1fr;
  }
`

export const useSectionSlug = ({ slug = null, sectionTitle = null }) => {
  const formatedSlug =
    slug && sectionTitle
      ? slug.current +
        `-` +
        sectionTitle.toLowerCase().replace(/\s+/g, "-").slice(0, 200)
      : ""
  return formatedSlug
}

export const SystemElementsBlock = ({ blockSections }) => {
  return (
    blockSections && (
      <StyledSystemElementsBlock id="systems-block">
        <SystemNavWrapper blockSections={blockSections} />
        <article>
          {blockSections.map(item => (
            <SystemElement {...item} />
          ))}
        </article>
      </StyledSystemElementsBlock>
    )
  )
}
