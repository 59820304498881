import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import is, { isNot } from "styled-is"

// Import components
import { StandardGridContainer, SanityPortableText } from "../common"

// Styles
const StyledImageText = styled(StandardGridContainer)`
  grid-row-gap: 2rem;
  > div:nth-child(1) {
    grid-column: 1 / span 14;
  }
  > div:nth-child(2) {
    grid-column: 2 / span 12;
  }
  @media ${props => props.theme.breakpoints.medium} {
    grid-gap: 0;
    > div {
      grid-row: 1;
    }
    > div:nth-child(1) {
      grid-column: 1 / span 6;
    }
    > div:nth-child(2) {
      grid-column: 8 / span 6;
      padding: 0;
      align-self: center;
    }
    ${is("reverseLayout")`
       > div:nth-child(1) {
        grid-column: 8 / span 7;
       }
       > div:nth-child(2) {
        grid-column: 2 / span 5r;
       }
    `}
  }
  @media ${props => props.theme.breakpoints.large} {
    ${isNot("reverseLayout")`
      > div:nth-child(2) {
        grid-column: 8 / span 5;
      }
    `}
  }
`

export const ImageText = ({
  _rawText,
  image,
  reverseLayout,
  marginTop,
  marginBottom,
}) => {
  return (
    <StyledImageText
      marginTop={marginTop}
      marginBottom={marginBottom}
      reverseLayout={reverseLayout}
    >
      <div>{image && <Img fluid={image.asset.fluid} />}</div>
      {_rawText && <SanityPortableText text={_rawText} />}
    </StyledImageText>
  )
}
