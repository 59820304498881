import React, { useState } from "react"
import styled from "styled-components"
import is from "styled-is"

import { motion } from "framer-motion"

import { Link } from "react-scroll"

// hooks
import { useSectionSlug } from "../systemElementsBlock"

// components
import { StyledHeading } from "../../common"

const StyledNavSection = styled.div`
  margin: 0.3rem 0;
  h6 {
    cursor: pointer;
  }
  article {
    overflow: hidden;

    * {
      font-size: ${props => props.theme.typography.sizes.regular};
    }
    a {
      opacity: 0.5;
      display: block;
      width: 100%;
      margin: 0.5rem 0;
      cursor: pointer;
      &.active {
        opacity: 1;
      }
      ${is("isOverview")`
        &.overviewActive{
          opacity: 1;
        }
      `}
    }
  }
`
// Animation Vars

const variants = {
  open: {
    opacity: 1,
    height: "auto",
  },
  closed: {
    opacity: 0,
    height: 0,
  },
}

const SectionLink = ({ slug, sectionTitle, ...props }) => {
  const sectionSlug = useSectionSlug({ slug, sectionTitle })
  return (
    <Link to={sectionSlug} hashSpy={true} spy={true} {...props}>
      {sectionTitle}
    </Link>
  )
}

const NavSection = ({
  title,
  systemSections,
  slug,
  setActiveSection,
  activeSection,
  subOpen,
  setSubOpen,
  onClose,
}) => {
  const sectionSlug = slug || {}
  const isOpen = subOpen === sectionSlug.current
  const isOverview = activeSection.parent === title && !activeSection.sub
  return (
    <StyledNavSection isOverview={isOverview}>
      <StyledHeading
        size="h6"
        as="h6"
        pullColor={activeSection.parent === title}
        onClick={() =>
          setSubOpen(prev => (prev !== slug.current ? slug.current : null))
        }
      >
        {title}
      </StyledHeading>
      <motion.article
        variants={variants}
        animate={isOpen ? "open" : "closed"}
        initial="closed"
        transition={{
          type: "spring",
          damping: 100,
        }}
      >
        <Link
          to={sectionSlug.current}
          hashSpy={true}
          spy={true}
          activeClass="overviewActive"
          onSetActive={() =>
            setActiveSection(prev => {
              return {
                ...prev,
                parent: title,
              }
            })
          }
          onClick={onClose || null}
        >
          Overview
        </Link>
        {systemSections.map(item => (
          <SectionLink
            {...item}
            slug={sectionSlug}
            onSetActive={() =>
              setActiveSection(prev => {
                return {
                  ...prev,
                  sub: item.sectionTitle,
                }
              })
            }
            onSetInactive={() => {
              setActiveSection(prev => {
                return {
                  ...prev,
                  sub: null,
                }
              })
            }}
            onClick={onClose || null}
          />
        ))}
      </motion.article>
    </StyledNavSection>
  )
}

export const SystemNav = ({
  sections,
  activeSection,
  setActiveSection,
  onClose,
}) => {
  const [subOpen, setSubOpen] = useState(null)
  return (
    <nav>
      {sections.map(item => (
        <NavSection
          {...item}
          activeSection={activeSection}
          setActiveSection={setActiveSection}
          subOpen={subOpen}
          setSubOpen={setSubOpen}
          onClose={onClose}
        />
      ))}
    </nav>
  )
}
