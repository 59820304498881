import React from "react"
import styled from "styled-components"
import { Element } from "react-scroll"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { globalHistory } from "@reach/router"

// Hooks
import { useSnackbar } from "react-simple-snackbar"
import { useSectionSlug } from "./systemElementsBlock"

// svg
import LinkIcon from "../../svg/link.svg"
// Components
import { StyledHeading, SanityPortableText } from "../common"
import { SystemBlockZone } from "./blocks"

// Styles
const StyledSystemElement = styled(Element)`
  padding: 2rem 0;
`
const StyledSystemSection = styled(Element)`
  padding: 4rem 0;
  button svg {
  }
`
const StyledSectionIntro = styled.div`
  margin-bottom: 3rem;
  > h2 {
    margin-bottom: 0.2em;
  }
`

const StyledAnchorButton = styled(StyledHeading)`
  margin-bottom: 0.2em;
  display: flex;
  align-items: center;
  padding: 0;
  svg {
    width: 20px;
    fill: ${props => props.theme.colors.primary.one};
    margin-left: 1rem;
  }
`

const SystemSection = ({
  sectionTitle,
  sectionBlocks,
  _rawSectionItro,
  slug,
  openSnackbar,
  url,
}) => {
  const sectionSlug = useSectionSlug({ slug, sectionTitle })
  return (
    <StyledSystemSection name={sectionSlug} id={sectionSlug}>
      <CopyToClipboard
        text={`${url}#${sectionSlug}`}
        onCopy={() => openSnackbar("Link Copied to clipboard")}
      >
        <StyledAnchorButton size="h5" as="button" pullColor>
          {sectionTitle}
          <LinkIcon />
        </StyledAnchorButton>
      </CopyToClipboard>
      {_rawSectionItro && <SanityPortableText text={_rawSectionItro} />}
      {sectionBlocks && <SystemBlockZone blocks={sectionBlocks} />}
    </StyledSystemSection>
  )
}

export const SystemElement = ({
  title,
  _rawSystemDescription,
  slug,
  systemSections,
}) => {
  const sectionSlug = slug || {}
  const url = globalHistory.location.href

  const [openSnackbar] = useSnackbar({
    style: {
      color: "white",
      fontFamily: "axiformasemibold",
      background: "#1ED761",
    },
  })
  return (
    <StyledSystemElement name={sectionSlug.current} id={sectionSlug.current}>
      <StyledSectionIntro>
        <CopyToClipboard
          text={`${url}#${sectionSlug.current}`}
          onCopy={() => openSnackbar("Link Copied to clipboard")}
        >
          <StyledAnchorButton pullColor size="h3" as="button">
            {title}
            <LinkIcon />
          </StyledAnchorButton>
        </CopyToClipboard>
        <SanityPortableText text={_rawSystemDescription} />
      </StyledSectionIntro>
      {systemSections.map(item => (
        <SystemSection
          {...item}
          slug={slug}
          openSnackbar={openSnackbar}
          url={url}
        />
      ))}
    </StyledSystemElement>
  )
}
