import React from "react"
import styled from "styled-components"
import is from "styled-is"
import { motion } from "framer-motion"

// Hooks
import { useWindowSize, useToggle } from "../../../hooks"
// SVG
import SearchIcon from "../../../svg/search.svg"
// Components
import { StyledHeading } from "../../common"

// Styles
const StyledTableFilters = styled.nav`
  section {
    margin-top: 2rem;
    > div {
      display: grid;
      grid-row-gap: 1rem;
      overflow: hidden;
      padding-bottom: 1rem;
    }
  }
`

const StyledSearchBox = styled.div`
  --block-color: ${props => props.theme.colors.primary.two};
  display: grid;
  grid-template-columns: 1fr 1.5rem;
  > input {
    grid-column: 1 / span 2;
    grid-row: 1;
    border-radius: none;
    border: none;
    border-bottom: 2px solid var(--block-color);
    font-size: ${props => props.theme.typography.sizes.h6};
    font-family: ${props => props.theme.typography.family.heading};
    color: var(--block-color);
    padding: 0.4rem 0;
    &:focus {
      outline: none;
    }
    ::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: var(--block-color);
    }
    ::-moz-placeholder {
      /* Firefox 19+ */
      color: var(--block-color);
    }
    :-ms-input-placeholder {
      /* IE 10+ */
      color: var(--block-color);
    }
    :-moz-placeholder {
      /* Firefox 18- */
      color: var(--block-color);
    }
  }
  svg {
    grid-column: 2;
    grid-row: 1;
    align-self: center;
  }
`

const StyledCatFilter = styled.button`
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  opacity: 0.8;
  transition: all 0.2s ease;
  font-family: ${props => props.theme.typography.family.standard};
  &:after {
    content: "";
    width: 8px;
    height: 8px;
    background: ${props => props.theme.colors.primary.two};
    display: block;
    border-radius: 100px;
    opacity: 0;
    transition: all 0.2s ease;
  }
  ${is("active")`
   opacity: 1;
    &:after{
      opacity: 1;
    }
  `}
`

const variants = {
  open: {
    opacity: 1,
    height: "auto",
  },
  closed: {
    opacity: 0,
    height: 0,
  },
}

const findDownloadCats = items => {
  let filters = []
  items.map(item => {
    return item.node.category.map(item => {
      return filters.indexOf(item) < 0 && filters.push(item)
    })
  })
  return filters
}

export const TableFilters = ({
  items,
  setSearchTerm,
  setActiveFilter,
  activeFilter,
}) => {
  const filterOptions = findDownloadCats(items || [])
  const [isOpen, setOpen, toggle] = useToggle(false) // eslint-disable-line
  const window = useWindowSize()
  return (
    <StyledTableFilters>
      <StyledSearchBox>
        <input
          placeholder="Search"
          type="text"
          onChange={e => {
            setSearchTerm(e.target.value)
          }}
        />
        <SearchIcon />
      </StyledSearchBox>
      <section>
        <StyledHeading size="h6" as="h6" onClick={toggle}>
          Category
        </StyledHeading>
        <motion.div
          variants={variants}
          initial={window.width < 1024 ? "closed" : "open"}
          animate={window.width < 1024 ? (isOpen ? "open" : "closed") : "open"}
          transition={{
            type: "spring",
            damping: 200,
          }}
        >
          <StyledCatFilter
            onClick={() => setActiveFilter(null)}
            active={!activeFilter}
          >
            All
          </StyledCatFilter>
          {filterOptions.map(item => (
            <StyledCatFilter
              onClick={() => setActiveFilter(item)}
              active={activeFilter === item}
            >
              {item}
            </StyledCatFilter>
          ))}
        </motion.div>
      </section>
    </StyledTableFilters>
  )
}
