import React, { useEffect } from "react"
import styled from "styled-components"
import Scroll from "react-scroll"
import Div100vh from "react-div-100vh"

// Hooks
import { useInView } from "react-intersection-observer"
import { useAppContext } from "../../state"
import { useWindowSize } from "../../hooks"
// Import common components
import {
  StandardContainer,
  StyledHeading,
  StyledCoverBackground,
  StyledBackgroundVideo,
  StyledTextLink,
} from "../common"

// Styles
const StyledPageBanner = styled(StandardContainer)`
  > div {
    display: flex;
    align-items: flex-end;
    padding-bottom: ${props => props.theme.padding.hMobile};
  }

  @media ${props => props.theme.breakpoints.medium} {
    > div {
      padding-bottom: ${props => props.theme.padding.hDesk};
    }
  }
`

const BannerBackground = ({ image, video, type }) => {
  return type === "image" && image ? (
    <StyledCoverBackground fluid={image.asset.fluid} />
  ) : (
    type === "video" && video && <StyledBackgroundVideo url={video} playing />
  )
}

export const PageBanner = ({
  heading,
  linkText,
  bannerVideo,
  bannerImage,
  bannerType,
  marginTop,
  marginBottom,
}) => {
  // App Context
  const { setReverseHeader } = useAppContext()

  const scroll = Scroll.animateScroll
  const { height } = useWindowSize()

  const [ref, inView] = useInView({
    /* Optional options */
    threshold: 0,
  })

  useEffect(() => {
    setReverseHeader(inView)
    console.log(inView)
    return () => {
      setReverseHeader(false)
    }
  }, [inView, setReverseHeader])

  return (
    <StyledPageBanner
      marginTop={marginTop}
      marginBottom={marginBottom}
      ref={ref}
    >
      <Div100vh>
        <BannerBackground
          image={bannerImage}
          video={bannerVideo}
          type={bannerType}
        />
        <StandardContainer padH>
          <StyledHeading as="h1" pullColor size="h2" noMargin>
            {heading}
          </StyledHeading>
          <StyledTextLink
            as="button"
            onClick={() => scroll.scrollTo(height)}
            pullColor
          >
            {linkText}
          </StyledTextLink>
        </StandardContainer>
      </Div100vh>
    </StyledPageBanner>
  )
}
