import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"

// Import components
import { StandardGridContainer, SanityPortableText } from "../common"

// Styles
const StyledGridImages = styled(StandardGridContainer)`
  margin-top: ${props => `${props.marginTop || 0}px`};
  margin-bottom: ${props => `${props.marginBottom || 0}px`};
  grid-row-gap: 4rem;
`

const StyledGridImageItem = styled.div`
  .sanity-portble-text {
    margin-bottom: 2rem;
  }

  grid-column-start: 2;
  grid-column-end: 14;
  @media ${props => props.theme.breakpoints.medium} {
    grid-column-start: ${props => props.gridStart || 1};
    grid-column-end: ${props => props.gridEnd || 2};
    align-self: ${props => props.gridAlign || "flex-start"};
  }
`

const ImageGridItem = ({
  image,
  gridStart,
  gridSpan,
  gridAlign,
  _rawCaption,
}) => {
  return (
    image && (
      <StyledGridImageItem
        gridStart={gridStart}
        gridEnd={gridSpan}
        gridAlign={gridAlign}
      >
        {_rawCaption && <SanityPortableText text={_rawCaption} />}
        {image.asset && <Img fluid={image.asset.fluid} />}
      </StyledGridImageItem>
    )
  )
}

export const ImageGrid = ({ gridImages, marginTop, marginBottom }) => {
  return (
    <StyledGridImages marginTop={marginTop} marginBottom={marginBottom}>
      {gridImages.map(item => (
        <ImageGridItem {...item} />
      ))}
    </StyledGridImages>
  )
}
