import React from "react"

// Components
import { StandardContainer, SanityPortableText } from "../common"

export const StandardText = ({ marginTop, marginBottom, _rawTextBlock }) => {
  return (
    <StandardContainer
      padH
      marginTop={marginTop}
      marginBottom={marginBottom}
      wrappedStandard
    >
      {_rawTextBlock && <SanityPortableText text={_rawTextBlock} />}
    </StandardContainer>
  )
}
