import React from "react"
import styled from "styled-components"
import { motion, AnimatePresence } from "framer-motion"

// Hooks
import { useToggle } from "../../hooks"
// SVG
import PlayIcon from "../../svg/play-icon.svg"

// Import common components
import { StandardContainer, StyledBackgroundVideo } from "../common"

// Styles
const StyledVideoBlock = styled(motion.div)`
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
  background: #000;
  > button {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: +1;
    background: rgba(0, 0, 0, 0.4);
    > svg {
      width: 60px;
      fill: ${props => props.theme.colors.primary.one};
    }
  }
`
const StyledBlockWrapper = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
  background: #000;
`

const VideoBlockPlayer = ({ video, isActive, setActive, ...props }) => {
  return (
    <StyledVideoBlock {...props}>
      {!isActive && (
        <button onClick={() => setActive(true)}>
          <PlayIcon />
        </button>
      )}
      <StyledBackgroundVideo
        url={video}
        muted={!isActive}
        controls={isActive}
        loop={!isActive}
        onEnded={() => setActive(false)}
      />
    </StyledVideoBlock>
  )
}

export const VideoBlock = ({
  wrapped,
  teaserVideo,
  fullVideo,
  marginBottom,
  marginTop,
}) => {
  const [isActive, setActive] = useToggle(false)
  return (
    <StandardContainer
      padH={wrapped}
      wrappedStandard={wrapped}
      marginTop={marginTop}
      marginBottom={marginBottom}
    >
      <StyledBlockWrapper>
        <AnimatePresence exitBeforeEnter>
          <VideoBlockPlayer
            key={`video-${isActive}`}
            video={isActive ? fullVideo : teaserVideo}
            isActive={isActive}
            setActive={setActive}
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
              y: 0,
            }}
            exit={{
              opacity: 0,
            }}
            transition={{
              transition: {
                type: "spring",
                damping: 600,
              },
            }}
          />
        </AnimatePresence>
      </StyledBlockWrapper>
    </StandardContainer>
  )
}
