import React, { useEffect } from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import { useEmblaCarousel } from "embla-carousel/react"

//Import common components
import { StandardContainer, StyledHeading } from "../common"

// Styles
const StyledCarouselWrapper = styled(StandardContainer)`
  overflow: hidden;
  > div:nth-child(1) {
    overflow: visible !important;
  }
`
const StyledCarouselCanvas = styled.div`
  display: flex;
  > div:first-child {
    overflow: visible !important;
  }
`
const StyledCarouselItem = styled.div`
  flex: 0 0 auto;
  box-sizing: content-box;
  padding-left: 2rem;
  width: 55vw;

  @media ${props => props.theme.breakpoints.medium} {
    width: 35vw;
  }
`

const CarouselItem = ({ asset, caption, date }) => {
  return (
    <StyledCarouselItem>
      <div>
        {date && <p>{date}</p>}
        {caption && <StyledHeading size="h6">{caption}</StyledHeading>}
      </div>
      {asset && <Img fluid={asset.fluid} />}
    </StyledCarouselItem>
  )
}

export const ImageCarousel = ({ images, marginTop, marginBottom }) => {
  const [EmblaCarouselReact, embla] = useEmblaCarousel({
    loop: false,
    dragFree: true,
    align: "start",
    containScroll: false,
  })

  useEffect(() => {
    if (!embla) return

    embla.on("select", () => {
      console.log(`Index is ${embla.selectedScrollSnap()}`)
    })
  }, [embla])
  return (
    <StyledCarouselWrapper
      marginTop={marginTop}
      marginBottom={marginBottom}
      padH
    >
      <EmblaCarouselReact>
        <StyledCarouselCanvas>
          {images.map(item => (
            <CarouselItem {...item} />
          ))}
        </StyledCarouselCanvas>
      </EmblaCarouselReact>
    </StyledCarouselWrapper>
  )
}
