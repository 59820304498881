import { useStaticQuery, graphql } from "gatsby"

export const useDownloadPacks = () => {
  const { allSanityDownload } = useStaticQuery(graphql`
    query {
      allSanityDownload {
        edges {
          node {
            _key
            _type
            _updatedAt(formatString: "DD MMM YY")
            title
            category
            file {
              asset {
                url
                _type
                extension
              }
            }
          }
        }
      }
    }
  `)
  return allSanityDownload.edges || []
}
