import React from "react"
import styled from "styled-components"

// Common Components
import { StyledHeading, StandardContainer } from "../common"

// Styled
const StyledCenteredHeading = styled(StandardContainer)`
  padding-top: 4rem;
  padding-bottom: 4rem;
`

export const CenteredHeading = ({ heading, size, marginTop, marginBottom }) => {
  return (
    <StyledCenteredHeading marginTop={marginTop} marginBottom={marginBottom}>
      <StyledHeading size={size} center measureNarrow>
        {heading}
      </StyledHeading>
    </StyledCenteredHeading>
  )
}
