import React from "react"
import styled from "styled-components"
import is from "styled-is"

// Import components
import {
  SanityPortableText,
  StyledHeading,
  StandardContainer,
} from "../../common"

// Styles
const StyledColorCard = styled.div`
  height: 0;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 130%;
  background: ${props => props.displayColor};
  position: relative;
  ${is("reverseText")`
      * {
        color: #ffffff;
      }
    `}
  > div {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: +1;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    top: 0;
    left: 0;
  }
  ul {
    list-style: none;
    padding-left: 0;
    display: grid;
    grid-row-gap: 1rem;
  }
`
const StyledColorCards = styled(StandardContainer)`
  > article {
    display: grid;
    grid-gap: 3rem;
  }

  @media ${props => props.theme.breakpoints.medium} {
    > article {
      grid-template-columns: 1fr 1fr;
    }
  }
`

const ColorCard = ({ colorValues, displayColor, colorName, reverseText }) => {
  return (
    <StyledColorCard
      displayColor={displayColor ? displayColor.hex : "#000"}
      reverseText={reverseText}
    >
      <div>
        <StyledHeading size="h6">{colorName}</StyledHeading>
        {colorValues && (
          <ul>
            {colorValues.map(item => (
              <li>{item}</li>
            ))}
          </ul>
        )}
      </div>
    </StyledColorCard>
  )
}

export const ColorCards = ({
  colors,
  _rawSectionDescription,
  marginTop,
  marginBottom,
}) => {
  return (
    <StyledColorCards marginTop={marginTop} marginBottom={marginBottom}>
      <SanityPortableText text={_rawSectionDescription} />
      <article>
        {colors.map(item => (
          <ColorCard {...item} />
        ))}
      </article>
    </StyledColorCards>
  )
}
