import React from "react"

// Import Blocks
import { TabbedImageSet, GridSet, ColorCards, ColorShades, TextBlock } from "./"

export const SystemBlockZone = ({ blocks, slug }) => {
  const zoneBlocks = blocks || []
  const block = zoneBlocks.map(block => {
    switch (block._type) {
      case "tabbedImages":
        return <TabbedImageSet key={block._key} {...block} slug={slug} />
      case "gridSet":
        return <GridSet key={block._key} {...block} slug={slug} />
      case "colorCards":
        return <ColorCards key={block._key} {...block} slug={slug} />
      case "colorShadeSet":
        return <ColorShades key={block._key} {...block} slug={slug} />
      case "textSection":
        return <TextBlock key={block._key} {...block} slug={slug} />
      default:
        return null
    }
  })
  return <>{block}</>
}
