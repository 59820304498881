import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import { motion } from "framer-motion"

// Hooks
import { useInView } from "react-intersection-observer"

// Import components
import { StandardContainer, MotionContainer, StyledHeading } from "../common"

// Styles
const StyledCardGrid = styled(StandardContainer)`
  background: ${props => props.theme.colors.primary.two};
  padding-top: 4rem;
  padding-bottom: 4rem;
  article {
    margin-top: 3rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0.5rem;
  }
  @media ${props => props.theme.breakpoints.medium} {
    padding-top: 6rem;
    padding-bottom: 6rem;
    article {
      margin-top: 5rem;
      grid-gap: 4rem;
      grid-template-columns: repeat(3, 1fr);
    }
  }
`
const StyledGridCard = styled(motion.div)`
  background: #fff;
  position: relative;
  padding: 0.5rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  h5 {
    position: absolute;
    top: 1rem;
    left: 1rem;
    z-index: +1;
  }
`

// Animation

const parentVars = {
  visible: {
    transition: {
      staggerChildren: 0.3,
      delayChildren: 0.8,
    },
  },
}

const headingVariants = {
  initial: {
    opacity: 0,
    x: -50,
  },
  visible: {
    opacity: 1,
    x: 0,
  },
}

const cardVariants = {
  initial: {
    opacity: 0,
    y: 50,
  },
  visible: {
    opacity: 1,
    y: 0,
  },
}

const GridCard = ({
  image,
  heading,
  pageReference,
  sectionReference,
  ...props
}) => {
  const { slug: pageSlug } = pageReference || {}
  const { slug: sectionSlug } = sectionReference || {}

  const cardPath = pageSlug
    ? `${pageSlug.current}${sectionSlug && "#" + sectionSlug.current}`
    : ""
  return (
    <StyledGridCard {...props}>
      <Link to={`/${cardPath}`}>
        <StyledHeading as="h5" size="h6">
          {heading}
        </StyledHeading>
        {image && <Img fluid={image.asset.fluid} />}
      </Link>
    </StyledGridCard>
  )
}

export const CardGrid = ({ cards, heading, marginTop, marginBottom }) => {
  const [ref, inView] = useInView({
    /* Optional options */
    threshold: 0,
    triggerOnce: true,
  })
  return (
    <StyledCardGrid marginTop={marginTop} marginBottom={marginBottom} padH>
      <MotionContainer
        wrappedNarrow
        variants={parentVars}
        initial="initial"
        animate={inView ? "visible" : "initial"}
      >
        <motion.div
          initial="initial"
          variants={headingVariants}
          transition={{
            type: "spring",
            damping: 300,
          }}
          ref={ref}
        >
          <StyledHeading pullColor size="h3" as="h3">
            {heading}
          </StyledHeading>
        </motion.div>
        <article>
          {cards.map((item, index) => (
            <GridCard
              {...item}
              key={index}
              variants={cardVariants}
              transition={{
                type: "spring",
                damping: 300,
              }}
            />
          ))}
        </article>
      </MotionContainer>
    </StyledCardGrid>
  )
}
