import React from "react"
import PropTypes from "prop-types"

import {
  PageBanner,
  StandardText,
  ImageText,
  InfoList,
  VideoBlock,
  GridText,
  ImageCarousel,
  CardGrid,
  CenteredHeading,
  ImageGrid,
  DownloadsTable,
} from "./"

import { SystemElementsBlock } from "../systemElements"

export const BlockZone = ({ blocks }) => {
  // Map blocks to return correct type
  const zoneBlocks = blocks || []
  const block = zoneBlocks.map(block => {
    switch (block._type) {
      case "pageBanner":
        return <PageBanner key={block._key} {...block} />
      case "standardTextBlock":
        return <StandardText key={block._key} {...block} />
      case "imageText":
        return <ImageText key={block._key} {...block} />
      case "infoList":
        return <InfoList key={block._key} {...block} />
      case "videoBlock":
        return <VideoBlock key={block._key} {...block} />
      case "gridText":
        return <GridText key={block._key} {...block} />
      case "imageCarousel":
        return <ImageCarousel key={block._key} {...block} />
      case "cardGrid":
        return <CardGrid key={block._key} {...block} />
      case "centeredHeading":
        return <CenteredHeading key={block._key} {...block} />
      case "imageGrid":
        return <ImageGrid key={block._key} {...block} />
      case "systemElementsBlock":
        return <SystemElementsBlock key={block._key} {...block} />
      case "downloadsTable":
        return <DownloadsTable key={block._key} {...block} />
      default:
        return null
    }
  })
  return <>{block}</>
}

BlockZone.propTypes = {
  blocks: PropTypes.array.isRequired,
}
