import React from "react"
import styled from "styled-components"

// Import common components
import { StandardContainer, SanityPortableText } from "../common"

// Styles
const StyledGridText = styled(StandardContainer)`
  display: grid;
  grid-row-gap: 2rem;

  @media ${props => props.theme.breakpoints.medium} {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 3rem;
  }
  @media ${props => props.theme.breakpoints.large} {
    grid-template-columns: repeat(${props => props.gridColumns}, 1fr);
  }
`

export const GridText = ({
  marginTop,
  marginBottom,
  textBlocks,
  gridColumns = 2,
}) => {
  return (
    <StyledGridText
      padH
      wrappedStandard
      marginTop={marginTop}
      marginBottom={marginBottom}
      gridColumns={gridColumns}
    >
      {textBlocks.map(({ _rawText }) => (
        <SanityPortableText text={_rawText} />
      ))}
    </StyledGridText>
  )
}
