import React from 'react';
import Img from 'gatsby-image';
import styled from 'styled-components';

import ReactPlayer from 'react-player';

// Components
import { SanityPortableText, StandardContainer } from '../../common';

// Styles
const StyledGridSet = styled.div`
  --grid-cols: ${(props) => props.gridColumns};
  display: grid;
  margin-top: 2rem;

  @media ${(props) => props.theme.breakpoints.medium} {
    grid-template-columns: repeat(var(--grid-cols), 1fr);
    grid-gap: 3rem;
  }
`;

const StyledVideoWrapper = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  position: relative;
  background: ${(props) => props.theme.colors.secondary.two};
  > div {
    object-fit: cover;
    position: absolute;
    height: 100%;
    width: 100%;
    mix-blend-mode: multiply;
  }
`;

const StyledGridItem = styled.div`
  .gatsby-image-wrapper {
    margin-bottom: 1rem;
  }
`;

const GridItem = ({ image, video, _rawCaption }) => {
  return (
    image && (
      <StyledGridItem>
        {!video && image.asset && <Img fluid={image.asset.fluid} />}
        {video && (
          <StyledVideoWrapper>
            <ReactPlayer url={video} playsinline playing loop width="100%" height="100%" />
          </StyledVideoWrapper>
        )}
        <SanityPortableText text={_rawCaption} />
      </StyledGridItem>
    )
  );
};

export const GridSet = ({
  gridColumns,
  gridItems,
  _rawSectionDescription,
  marginTop,
  marginBottom,
}) => {
  return (
    <StandardContainer marginTop={marginTop} marginBottom={marginBottom}>
      <SanityPortableText text={_rawSectionDescription} />
      <StyledGridSet gridColumns={gridColumns}>
        {gridItems.map((item) => (
          <GridItem {...item} />
        ))}
      </StyledGridSet>
    </StandardContainer>
  );
};
