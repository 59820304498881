import React from "react"
import styled from "styled-components"
import { motion } from "framer-motion"

// Hooks
import { useInView } from "react-intersection-observer"
// Import Components
import { StyledHeading, MotionContainer } from "../common"

const StyledInfoList = styled(MotionContainer)`
  > div:nth-child(1) {
    margin-bottom: 2rem;
  }
  ol {
    list-style: none;
    counter-reset: steps;
  }
  ol li {
    counter-increment: steps;
    padding: 2rem 0;
    display: flex;
    h3 {
      line-height: 1;
    }
    &:before {
      content: "0" counter(steps) ".";
      font-size: ${props => props.theme.typography.sizes.h6};
      font-family: ${props => props.theme.typography.family.heading};
      margin-right: 1rem;
    }
  }
`
// Animation
const parentVariants = {
  initial: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.3,
      delayChildren: 0.8,
    },
  },
}
const listVariants = {
  initial: {
    opacity: 0,
    y: 50,
  },
  visible: {
    opacity: 1,
    y: 0,
  },
}
const headingVariants = {
  initial: {
    opacity: 0,
    x: -50,
  },
  visible: {
    opacity: 1,
    x: 0,
  },
}

export const InfoList = ({ heading, listItems, marginTop, marginBottom }) => {
  const [ref, inView] = useInView({
    /* Optional options */
    threshold: 0,
    triggerOnce: true,
  })
  return (
    <StyledInfoList
      padH
      wrappedStandard
      marginTop={marginTop}
      marginBottom={marginBottom}
      variants={parentVariants}
      initial="initial"
      animate={inView ? "visible" : "initial"}
    >
      <motion.div
        initial="initial"
        animate="visible"
        variants={headingVariants}
        transition={{
          type: "spring",
          damping: 300,
        }}
        ref={ref}
      >
        <StyledHeading pullColor size="h5" as="h5" noMargin>
          {heading}
        </StyledHeading>
      </motion.div>
      <ol>
        {listItems.map(item => (
          <motion.li
            variants={listVariants}
            key={item}
            transition={{
              type: "spring",
              damping: 300,
            }}
          >
            <StyledHeading size="h3" as="h3" noMargin>
              {item}
            </StyledHeading>
          </motion.li>
        ))}
      </ol>
    </StyledInfoList>
  )
}
