import React, { useState, useEffect } from "react"
import styled from "styled-components"

// Hooks

import { useDownloadPacks } from "../../../GraphQl/useDownloadPacks"

// SVG

import LinkIcon from "../../../svg/link.svg"
import DownloadIcon from "../../../svg/download.svg"
// Components
import { TableFilters } from "./tableFilters"
import { StyledHeading, StandardContainer } from "../../common"

// Styles
const StyledDownloadsTable = styled(StandardContainer)`
  > article {
    display: grid;
  }
  table {
    width: 100%;
    th {
      text-align: left;
      padding-bottom: 1rem;
    }
  }
  @media ${props => props.theme.breakpoints.large} {
    > article {
      grid-template-columns: 300px 1fr;
      grid-column-gap: 4rem;
    }
  }
`
const StyledDownloadRow = styled.tr`
  > td:last-child {
    display: flex;
    justify-content: flex-end;
    > a {
      margin-left: 1rem;
    }
    svg {
      width: 15px;
      fill: ${props => props.theme.colors.primary.two};
    }
  }
  td {
    padding: 1rem 0;
  }
`

const DownloadsRow = ({ title, _updatedAt, file }) => {
  const download = file || {}
  const { extension, url } = download.asset
  return (
    <StyledDownloadRow>
      <td>{title}</td>
      <td>{_updatedAt}</td>
      <td>{extension}</td>
      <td>
        <a href={url} target="_blank" rel="noreferrer">
          <LinkIcon />
        </a>
        <a href={url} download rel="noreferrer">
          <DownloadIcon />
        </a>
      </td>
    </StyledDownloadRow>
  )
}

export const DownloadsTable = ({ heading, marginTop, marginBottom }) => {
  const items = useDownloadPacks()
  const [downloadPacks, setDownloadPacks] = useState([])
  const [searchTerm, setSearchTerm] = useState("")
  const [activeFilter, setActiveFilter] = useState(null)
  // Update Results
  useEffect(() => {
    const results = items.filter(item => {
      const { title, category } = item.node
      return activeFilter
        ? title.toLowerCase().includes(searchTerm.toLowerCase()) &&
            category.indexOf(activeFilter) > -1
        : title.toLowerCase().includes(searchTerm.toLowerCase())
    })
    setDownloadPacks(results)
  }, [searchTerm, items, activeFilter])

  return (
    <StyledDownloadsTable
      marginTop={marginTop}
      marginBottom={marginBottom}
      padH
    >
      <StyledHeading size="h3" as="h1" pullColor>
        {heading}
      </StyledHeading>
      <article>
        <TableFilters
          activeFilter={activeFilter}
          setActiveFilter={setActiveFilter}
          setSearchTerm={setSearchTerm}
          items={items}
        />
        <table>
          <tr>
            <th>File Name</th>
            <th>Updated</th>
            <th>File Type</th>
          </tr>
          {downloadPacks.map(({ node }) => (
            <DownloadsRow {...node} />
          ))}
        </table>
      </article>
    </StyledDownloadsTable>
  )
}
